import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-european-box',
  templateUrl: './european-box.component.html',
  styleUrls: ['./european-box.component.css']
})
export class EuropeanBoxComponent implements OnInit {

  @Input() program: any = {};

  constructor() { }

  ngOnInit() {
  }

  public goToProgram(url) {
    if (url) {
      var win = window.open(url, '_blank');
    }
  }

}
