import { Directive, ElementRef, Input, HostListener } from '@angular/core';

@Directive({
  selector: '[appMenuItemHighlight]'
})
export class MenuItemHighlightDirective {

  constructor(private el: ElementRef) { }

  @Input() defaultColor: string;

  @Input('appMenuItemHighlight') highlightColor: string;

  @HostListener('mouseenter') onMouseEnter() {
    this.highlight(this.highlightColor || '#ffffff');
  }

  @HostListener('mouseleave') onMouseLeave() {
    this.highlight(this.defaultColor);
  }

  private highlight(color: string) {
    this.el.nativeElement.style.color = color;
  }

}
