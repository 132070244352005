import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { DalService } from "../services/dal.service";
import { HttpClient } from "@angular/common/http";
import * as _ from "underscore";
import { LanguageService } from "../services/language.service";

@Component({
  selector: "app-supporters",
  templateUrl: "./supporters.component.html",
  styleUrls: ["./supporters.component.css"],
})
export class SupportersComponent implements OnInit {
  public pageData: any;
  public activeFilterBox = 0;

  public supporters: any[] = [];
  public number: any;
  public numberSplitted: any = [];
  public words: any;
  public wordsSplitted: any = [];

  public totalCount = 0;
  public pageCountRequest = 1;

  public lang: { code: string, name: string };

  constructor(
    public dalService: DalService,
    private http: HttpClient,
    public cdr: ChangeDetectorRef,
    public languageService: LanguageService
  ) {}

  public async ngOnInit() {
    this.lang = this.languageService.getLanguage();
    this.number = "000";
    this.numberSplitted = this.number.split("");
    this.words = ["ΜΑΖΙ", "ΚΑΤΑΦΕΡΝΟΥΜΕ", "ΠΕΡΙΣΣΟΤΕΡΑ"];
    this.splitLetters(this.words);

    this.getData();
  }

  public async getData() {
    this.pageData = await this.get();
    this.supporters = this.pageData.supporters;
    this.number = this.pageData.supporters_number;
    this.totalCount =
      this.pageData && this.pageData.totalcount ? this.pageData.totalcount : 0;
    this.numberSplitted = this.number.split("");
    this.cdr.detectChanges();
  }

  public get() {
    return new Promise((resolve, reject) => {
      return this.http
        .get<any>("https://adminpanel.knowl.gr/apionline/getpageSupporters?lang=" + this.lang.code)
        .subscribe(
          (res) => {
            resolve(res);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  public loadMore() {
    return new Promise((resolve, reject) => {
      return this.http
        .get<any>(
          "https://adminpanel.knowl.gr/apionline/getpageSupportersMore?p=" +
            this.pageCountRequest + "&lang=" + this.lang.code
        )
        .subscribe(
          (res: any) => {
            this.supporters =
              res && res.supporters.length
                ? this.supporters.concat(res.supporters)
                : [];
            this.pageCountRequest++;
            this.cdr.detectChanges();
            resolve(res);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  private splitLetters(words) {
    _.each(words, (word: any) => {
      this.wordsSplitted.push(word.split(""));
    });
  }

  public setActiveFilter(value) {
    this.activeFilterBox = value;
  }
}
