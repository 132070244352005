import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TeamService {

  constructor() { }

  public getTeamMember(id) {
    const descriptions = {}

    return descriptions[id];
  }

}
