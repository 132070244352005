import { Component, OnInit, ChangeDetectorRef, ViewRef } from "@angular/core";
import * as _ from "underscore";
import { DalService } from "../services/dal.service";
import { HttpClient } from "@angular/common/http";
import { LanguageService } from "../services/language.service";

@Component({
  selector: "app-testimonials",
  templateUrl: "./testimonials.component.html",
  styleUrls: ["./testimonials.component.css"],
})
export class TestimonialsComponent implements OnInit {
  public pageData: any;

  public number_text: string = "0000";
  public number: any;
  public numberSplitted: any = [];
  public words: any;
  public wordsSplitted: any = [];
  public wordsSplitted2: any = [];

  public pageCountRequest = 1;
  public testimonialListOne: any[] = [];
  public testimonialListTwo: any[] = [];

  public lang: { code: string, name: string };

  constructor(
    public dalService: DalService,
    private http: HttpClient,
    public cdr: ChangeDetectorRef,
    public languageService: LanguageService
  ) {}

  public async ngOnInit() {
    this.lang = this.languageService.getLanguage();
    this.number_text = "0000";
    this.numberSplitted = this.number_text.split("");
    this.splitLetters(["ΚΑΛΕΣ"]);
    this.splitLetters2(["ΜΑΡΤΥΡΙΕΣ"]);

    this.getData();
  }

  public async getData() {
    this.pageData = await this.get();

    this.number = this.pageData.totalcount;

    this.number_text = this.pageData.totalcount_text;
    this.numberSplitted = this.number_text.split("");

    this.testimonialListOne =
      this.pageData.testimonials1 && this.pageData.testimonials1.length
        ? this.testimonialListOne.concat(this.pageData.testimonials1)
        : [];
    this.testimonialListTwo =
      this.pageData.testimonials2 && this.pageData.testimonials2.length
        ? this.testimonialListTwo.concat(this.pageData.testimonials2)
        : [];

    // Apply changes
    if (!(this.cdr as ViewRef).destroyed) {
      this.cdr.detectChanges();
    }
  }

  public get() {
    return new Promise((resolve, reject) => {
      return this.http
        .get<any>("https://adminpanel.knowl.gr/apionline/getpageTestimonials?lang=" + this.lang.code)
        .subscribe(
          (res) => {
            resolve(res);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  public loadMore() {
    return new Promise((resolve, reject) => {
      return this.http
        .get<any>(
          "https://adminpanel.knowl.gr/apionline/getpageTestimonialsMore?p=" +
            this.pageCountRequest + "&lang=" + this.lang.code
        )
        .subscribe(
          (res: any) => {
            this.testimonialListOne =
              res.testimonials1 && res.testimonials1.length
                ? this.testimonialListOne.concat(res.testimonials1)
                : [];
            this.testimonialListTwo =
              res.testimonials2 && res.testimonials2.length
                ? this.testimonialListTwo.concat(res.testimonials2)
                : [];
            this.pageCountRequest++;
            // Apply changes
            if (!(this.cdr as ViewRef).destroyed) {
              this.cdr.detectChanges();
            }
            resolve(res);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  private splitLetters(words) {
    _.each(words, (word: any) => {
      this.wordsSplitted.push(word.split(""));
    });
  }

  private splitLetters2(words) {
    _.each(words, (word: any) => {
      this.wordsSplitted2.push(word.split(""));
    });
  }
}
