import { Injectable, Output, EventEmitter } from '@angular/core';
import * as _ from 'underscore';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  @Output() modalShape = new EventEmitter<Object>();
  @Output() modalClosed = new EventEmitter<Object>();
  public modalInfoEvent: EventEmitter<any> = new EventEmitter();
  public sourceStat: string = null;
  private modals: any[] = [];


  public add(modal: any) {
    // add modal to array of active modals
    this.modals.push(modal);
  }

  public remove(id: string) {
    // remove modal from array of active modals
    this.modals = this.modals.filter(x => x.id !== id);
  }

  public open(id: string) {
    // open modal specified by id
    let modal: any = this.modals.filter(x => x.id === id)[0];
    modal.open();
  }

  public close(id: string) {
    // Clear source stat name
    this.sourceStat = null;
    // close modal specified by id
    let modal: any = this.modals.filter(x => x.id === id)[0];
    try {
      modal.close();
      this.modalClosed.emit();
    } catch (e) {
      // Modal does not exist
    }
  }
}
