import {
  Component,
  OnInit,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
  HostListener,
  ViewRef,
} from "@angular/core";
import { DalService } from "../services/dal.service";
import { HttpClient } from "@angular/common/http";
import * as _ from "underscore";
import { Title } from "@angular/platform-browser";
import { ModalService } from "../services/modal.service";
import { LanguageService } from "../services/language.service";

@Component({
  selector: "app-philanthropists",
  templateUrl: "./philanthropists.component.html",
  styleUrls: ["./philanthropists.component.css"],
})
export class PhilanthropistsComponent implements OnInit {
  public pageData: any;
  public activeFilterBox = 0;

  public philanthropists: any[] = [];
  public number: any;
  public numberSplitted: any = [];
  public words: any;
  public wordsSplitted: any = [];

  public totalCount = 0;
  public pageCountRequest = 0;
  public filterUsed = 0;

  public member: any = {};
  public lang: { code: string, name: string };

  @ViewChild("modalScroll") modalScroll: ElementRef;

  constructor(
    public dalService: DalService,
    private http: HttpClient,
    public cdr: ChangeDetectorRef,
    private titleService: Title,
    private modalService: ModalService,
    public languageService: LanguageService
  ) {}

  public async ngOnInit() {
    this.lang = this.languageService.getLanguage();
    this.words = ["ΕΙΣΤΕ", "Η ", "ΔΥΝΑΜΗ", "ΜΑΣ"];
    this.splitLetters(this.words);
    // Get data
    this.getData();
  }

  public async getData() {
    this.pageData = await this.get();
    this.philanthropists = this.pageData.philanthropists;
    this.totalCount =
      this.pageData && this.pageData.totalcount ? this.pageData.totalcount : 0;
    this.number = this.pageData.philanthropists_number;
    this.numberSplitted = this.number.split("");
    // Apply changes
    if (!(this.cdr as ViewRef).destroyed) {
      this.cdr.detectChanges();
    }
  }

  public get() {
    return new Promise((resolve, reject) => {
      return this.http
        .get<any>(
          "https://adminpanel.knowl.gr/apionline/getpagePhilanthropists?lang=" + this.lang.code
        )
        .subscribe(
          (res) => {
            resolve(res);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  public getBoxes(param) {
    return new Promise((resolve, reject) => {
      // tslint:disable-next-line: max-line-length
      return this.http
        .get<any>(
          "https://adminpanel.knowl.gr/apionline/getpagePhilanthropists?f=" +
            param + "&lang=" + this.lang.code
        )
        .subscribe(
          (res: any) => {
            this.philanthropists =
              res && res.philanthropists.length ? res.philanthropists : [];
            this.totalCount =
              this.pageData && this.pageData.totalcount
                ? this.pageData.totalcount
                : 0;
            // Apply changes
            if (!(this.cdr as ViewRef).destroyed) {
              this.cdr.detectChanges();
            }
            resolve(res);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  public loadMore() {
    if ((window as any).getFontSize) {
      (window as any).getFontSize();
    }
    return new Promise((resolve, reject) => {
      // tslint:disable-next-line: max-line-length
      return this.http
        .get<any>(
          "https://adminpanel.knowl.gr/apionline/getpagePhilanthropistsMore?p=" +
            this.pageCountRequest +
            "&f=" +
            this.filterUsed + "&lang=" + this.lang.code
        )
        .subscribe(
          (res: any) => {
            // tslint:disable-next-line: max-line-length
            this.philanthropists =
              res.philanthropists && res.philanthropists.length
                ? this.philanthropists.concat(res.philanthropists)
                : this.philanthropists;
            this.pageCountRequest++;
            this.cdr.detectChanges();
            resolve(res);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  public openRelateModal(member): void {
    // Clear member
    this.member = {};
    // Apply changes
    if (!(this.cdr as ViewRef).destroyed) {
      this.cdr.detectChanges();
    }
    this.member = member;
    // Trigger opening modal
    this.modalService.open("team-member-modal");
    // Scroll content to top
    this.scrollToTop();
    // Apply changes
    if (!(this.cdr as ViewRef).destroyed) {
      this.cdr.detectChanges();
    }
    // Set tab title
    this.setTitle(
      "Knowl - " + member.phil_firstname + " " + member.phil_lastname
    );
  }

  public closeModal() {
    // Clear member
    this.member = {};
    this.modalService.close("team-member-modal");
    // Apply changes
    if (!(this.cdr as ViewRef).destroyed) {
      this.cdr.detectChanges();
    }
  }

  public setActiveFilter(value) {
    if ((window as any).getFontSize) {
      (window as any).getFontSize();
    }
    this.activeFilterBox = value;
    // Init again the page count
    this.pageCountRequest = 0;
    // Keep current filter
    this.filterUsed = value;
    // Call function
    this.getBoxes(value);
  }

  private scrollToTop() {
    this.modalScroll.nativeElement.scrollTo(0, 0);
  }

  private setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }

  private splitLetters(words) {
    _.each(words, (word: any) => {
      // this.wordsSplitted.push(word.split('').reverse());
      this.wordsSplitted.push(word.split(""));
    });

    // this.wordsSplitted.reverse();
  }

  @HostListener("document:keydown", ["$event"]) public closePhilanthropistModal(
    event?: KeyboardEvent
  ) {
    if (!event || event.keyCode === 27) {
      this.closeModal();
    }
  }
}
