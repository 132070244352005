import { Component, OnInit, ChangeDetectorRef, ViewRef } from "@angular/core";
import { DalService } from "../services/dal.service";
import { HttpClient } from "@angular/common/http";
import { LanguageService } from "../services/language.service";

@Component({
  selector: "app-clients",
  templateUrl: "./clients.component.html",
  styleUrls: ["./clients.component.css"],
})
export class ClientsComponent implements OnInit {
  public lang: { code: string, name: string };
  public pageData: any;

  public logos: any[] = [];
  public partA: any[] = [];
  public partB: any[] = [];
  public partC: any[] = [];

  constructor(
    public dalService: DalService,
    private http: HttpClient,
    public cdr: ChangeDetectorRef,
    public languageService: LanguageService
  ) {}

  public async ngOnInit() {
    this.lang = this.languageService.getLanguage();
    this.getData();
  }

  public async getData() {
    this.pageData = await this.get();

    this.logos = this.pageData.logos;

    this.partA = [
      this.logos[0],
      this.logos[1],
      this.logos[2],
      this.logos[3],
      this.logos[4],
      this.logos[5],
      this.logos[6],
      this.logos[7],
      this.logos[8],
    ];
    this.partB = [
      this.logos[9],
      this.logos[10],
      this.logos[11],
      this.logos[12],
      this.logos[13],
      this.logos[14],
    ];
    this.partC = this.logos.slice(15);

    // Apply changes
    if (!(this.cdr as ViewRef).destroyed) {
      this.cdr.detectChanges();
    }
  }

  public get() {
    return new Promise((resolve, reject) => {
      return this.http
        .get<any>("https://adminpanel.knowl.gr/apionline/getpageClients?lang=" + this.lang.code)
        .subscribe(
          (res) => {
            resolve(res);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }
}
