import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { SocialComponent } from './social/social.component';
import { CorporateComponent } from './corporate/corporate.component';
import { ParticipateComponent } from './participate/participate.component';
import { ClientsComponent } from './clients/clients.component';
import { EuropeanComponent } from './european/european.component';
import { ServicesComponent } from './services-screen/services.component';
import { PhilanthropistsComponent } from './philanthropists/philanthropists.component';
import { SupportersComponent } from './supporters/supporters.component';
import { SocialProgramsComponent } from './social-programs/social-programs.component';
import { HomellonComponent } from './homellon/homellon.component';
import { TestimonialsComponent } from './testimonials/testimonials.component';
import { PtiComponent } from './pti/pti.component';
import { PtiProgramsComponent } from './pti-programs/pti-programs.component';
import { MatchingFitsComponent } from './matching-fits/matching-fits.component';
import { SkillsSeminarsComponent } from './skills-seminars/skills-seminars.component';
import { MellonAbilitiesComponent } from './mellon-abilities/mellon-abilities.component';

const routes: Routes = [
  { path: 'page-not-found', component: PageNotFoundComponent, data: { title: 'Page not found' } },
  { path: 'home', component: HomeComponent, data: { title: 'Home' } },
  { path: 'social', component: SocialComponent, data: { title: 'Social' } },
  { path: 'corporate', component: CorporateComponent, data: { title: 'Corporate' } },
  { path: 'social/participate', component: ParticipateComponent, data: { title: 'Participate' } },
  { path: 'corporate/clients', component: ClientsComponent, data: { title: 'Clients' } },
  { path: 'corporate/european', component: EuropeanComponent, data: { title: 'European projects' } },
  { path: 'corporate/services', component: ServicesComponent, data: { title: 'Services' } },
  { path: 'corporate/services/matching-fits', component: MatchingFitsComponent, data: { title: 'Matching Fits' } },
  { path: 'social/programs', component: SocialProgramsComponent, data: { title: 'Programs' } },
  { path: 'social/friends/philanthropists', component: PhilanthropistsComponent, data: { title: 'Philanthropists' } },
  { path: 'social/friends/supporters', component: SupportersComponent, data: { title: 'Supporters' } },
  { path: 'social/programs/homellon', component: HomellonComponent },
  { path: 'social/programs/mellonabilities', component: MellonAbilitiesComponent },
  { path: 'social/programs/pti', component: PtiComponent },
  { path: 'social/programs/pti/:id', component: PtiProgramsComponent },
  { path: 'social/programs/skills-seminars', component: SkillsSeminarsComponent },
  { path: 'testimonials', component: TestimonialsComponent },
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
