import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from './services/language.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'knowl-app';

  constructor(translate: TranslateService, private languageService: LanguageService) {
    const lang = this.languageService.getLanguage();
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('el');

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    translate.use(lang.code || 'el');
  }
}
