import { Component, OnInit } from '@angular/core';
import { LanguageService } from '../services/language.service';

@Component({
  selector: 'app-participate',
  templateUrl: './participate.component.html',
  styleUrls: ['./participate.component.css']
})
export class ParticipateComponent implements OnInit {

  contactForm: any = {
    email: '',
    firstname: '',
    lastname: '',
    message: ''
  };

  public lang: { code: string, name: string };
  
  constructor(private languageService: LanguageService) { }

  ngOnInit() {
     this.lang = this.languageService.getLanguage();
  }

  sendMessage() {

  }

}
