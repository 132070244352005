import { Injectable } from '@angular/core';
import * as _ from 'underscore';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {

  constructor() { }

  private menuItems() {
    return {
      '/home': {
        name: 'Home',
        valuesLeft: [
          { value: 'Corporate', url: '/corporate', children: [], isSelected: false },
          { value: 'Social', url: '/social', children: [], isSelected: false }
        ],
        valuesRight: [],
        bgColor: '#000000',
        color: '#ffffff',
        hoverColor: '#ffffff',
        isUpperCase: true
      },
      '/social': {
        name: 'Social',
        valuesRight: [
          { value: 'programs', url: '/social/programs', children: [], isSelected: false },
          { value: 'supporters', url: '/social/friends/supporters', children: [], isSelected: false },
          { value: 'philanthropists', url: '/social/friends/philanthropists', children: [], isSelected: false },
          { value: 'participate', url: '/social/participate', children: [], isSelected: false }
        ],
        valuesLeft: [
          { value: 'Corporate', url: '/corporate', isSelected: false },
          { value: 'Social', url: '/social', isSelected: true }
        ],
        bgColor: '#000000',
        color: '#ffffff',
        hoverColor: '#ffffff',
        isUpperCase: true
      },
      '/corporate': {
        name: 'Corporate',
        valuesRight: [
          { value: 'services', url: '/corporate/services', children: [], isSelected: false },
          { value: 'european', url: '/corporate/european', children: [], isSelected: false },
          { value: 'clients', url: '/corporate/clients', children: [], isSelected: false }
        ],
        valuesLeft: [
          { value: 'Social', url: '/social', isSelected: false },
          { value: 'Corporate', url: '/corporate', isSelected: true }
        ],
        bgColor: '#000000',
        color: '#ffffff',
        hoverColor: '#ffffff',
        isUpperCase: true
      },
      '/corporate/clients': {
        name: 'Clients',
        valuesRight: [
          { value: 'services', url: '/corporate/services', children: [], isSelected: false },
          { value: 'european', url: '/corporate/european', children: [], isSelected: false },
          { value: 'clients', url: '/corporate/clients', children: [], isSelected: true }
        ],
        valuesLeft: [
          { value: 'Social', url: '/social', isSelected: false },
          { value: 'Corporate', url: '/corporate', isSelected: true },
        ],
        bgColor: '#000000',
        color: '#ffffff',
        hoverColor: '#ffffff',
        isUpperCase: true
      },
      '/corporate/european': {
        name: 'European projects',
        valuesRight: [
          { value: 'services', url: '/corporate/services', children: [], isSelected: false },
          { value: 'european', url: '/corporate/european', children: [], isSelected: true },
          { value: 'clients', url: '/corporate/clients', children: [], isSelected: false }
        ],
        valuesLeft: [
          { value: 'Social', url: '/social', isSelected: false },
          { value: 'Corporate', url: '/corporate', isSelected: true },
        ],
        bgColor: '#000000',
        color: '#ffffff',
        hoverColor: '#ffffff',
        isUpperCase: true
      },
      '/corporate/services': {
        name: 'Services',
        valuesRight: [
          { value: 'services', url: '/corporate/services', children: [], isSelected: true },
          { value: 'european', url: '/corporate/european', children: [], isSelected: false },
          { value: 'clients', url: '/corporate/clients', children: [], isSelected: false }
        ],
        valuesLeft: [
          { value: 'Social', url: '/social', isSelected: false },
          { value: 'Corporate', url: '/corporate', isSelected: true },
        ],
        bgColor: '#000000',
        color: '#ffffff',
        hoverColor: '#ffffff',
        isUpperCase: true
      },
      '/corporate/services/matching-fits': {
        name: 'Matching Fits',
        valuesRight: [
          { value: 'services', url: '/corporate/services', children: [], isSelected: false },
          { value: 'european', url: '/corporate/european', children: [], isSelected: false },
          { value: 'clients', url: '/corporate/clients', children: [], isSelected: false }
        ],
        valuesLeft: [
          { value: 'Social', url: '/social', isSelected: false },
          { value: 'Corporate', url: '/corporate', isSelected: true },
        ],
        bgColor: '#000000',
        color: '#ffffff',
        hoverColor: '#ffffff',
        isUpperCase: true
      },
      '/social/participate': {
        name: 'Participate',
        valuesRight: [
          { value: 'programs', url: '/social/programs', children: [], isSelected: false },
          { value: 'supporters', url: '/social/friends/supporters', children: [], isSelected: false },
          { value: 'philanthropists', url: '/social/friends/philanthropists', children: [], isSelected: false },
          { value: 'participate', url: '/social/participate', children: [], isSelected: true }
        ],
        valuesLeft: [
          { value: 'Corporate', url: '/corporate', isSelected: false },
          { value: 'Social', url: '/social', isSelected: true }
        ],
        bgColor: '#000000',
        color: '#ffffff',
        hoverColor: '#ffffff',
        isUpperCase: true
      },
      '/social/friends/philanthropists': {
        name: 'Philanthropists',
        valuesRight: [
          { value: 'programs', url: '/social/programs', children: [], isSelected: false },
          { value: 'supporters', url: '/social/friends/supporters', children: [], isSelected: false },
          { value: 'philanthropists', url: '/social/friends/philanthropists', children: [], isSelected: true },
          { value: 'participate', url: '/social/participate', children: [], isSelected: false }
        ],
        valuesLeft: [
          { value: 'Corporate', url: '/corporate', isSelected: false },
          { value: 'Social', url: '/social', isSelected: true }
        ],
        bgColor: '#000000',
        color: '#ffffff',
        hoverColor: '#ffffff',
        isUpperCase: true
      },
      '/social/friends/supporters': {
        name: 'Supporters',
        valuesRight: [
          { value: 'programs', url: '/social/programs', children: [], isSelected: false },
          { value: 'supporters', url: '/social/friends/supporters', children: [], isSelected: true },
          { value: 'philanthropists', url: '/social/friends/philanthropists', children: [], isSelected: false },
          { value: 'participate', url: '/social/participate', children: [], isSelected: false }
        ],
        valuesLeft: [
          { value: 'Corporate', url: '/corporate', isSelected: false },
          { value: 'Social', url: '/social', isSelected: true }
        ],
        bgColor: '#000000',
        color: '#ffffff',
        hoverColor: '#ffffff',
        isUpperCase: true
      },
      '/social/programs': {
        name: 'Programs',
        valuesRight: [
          { value: 'programs', url: '/social/programs', children: [], isSelected: true },
          { value: 'supporters', url: '/social/friends/supporters', children: [], isSelected: false },
          { value: 'philanthropists', url: '/social/friends/philanthropists', children: [], isSelected: false },
          { value: 'participate', url: '/social/participate', children: [], isSelected: false }
        ],
        valuesLeft: [
          { value: 'Corporate', url: '/corporate', isSelected: false },
          { value: 'Social', url: '/social', isSelected: true }
        ],
        bgColor: '#000000',
        color: '#ffffff',
        hoverColor: '#ffffff',
        isUpperCase: true
      },
      '/social/programs/homellon': {
        name: 'Homellon',
        valuesRight: [
          { value: 'programs', url: '/social/programs', children: [], isSelected: false },
          { value: 'supporters', url: '/social/friends/supporters', children: [], isSelected: false },
          { value: 'philanthropists', url: '/social/friends/philanthropists', children: [], isSelected: false },
          { value: 'participate', url: '/social/participate', children: [], isSelected: false }
        ],
        valuesLeft: [
          { value: 'Corporate', url: '/corporate', isSelected: false },
          { value: 'Social', url: '/social', isSelected: false }
        ],
        bgColor: '#000000',
        color: '#ffffff',
        hoverColor: '#ffffff',
        isUpperCase: true
      },
      '/social/programs/mellonabilities': {
        name: 'MellonAbilities',
        valuesRight: [
          { value: 'programs', url: '/social/programs', children: [], isSelected: false },
          { value: 'supporters', url: '/social/friends/supporters', children: [], isSelected: false },
          { value: 'philanthropists', url: '/social/friends/philanthropists', children: [], isSelected: false },
          { value: 'participate', url: '/social/participate', children: [], isSelected: false }
        ],
        valuesLeft: [
          { value: 'Corporate', url: '/corporate', isSelected: false },
          { value: 'Social', url: '/social', isSelected: false }
        ],
        bgColor: '#000000',
        color: '#ffffff',
        hoverColor: '#ffffff',
        isUpperCase: true
      },
      '/social/programs/pti': {
        name: 'Social Programs PTI',
        valuesRight: [
          { value: 'programs', url: '/social/programs', children: [], isSelected: false },
          { value: 'supporters', url: '/social/friends/supporters', children: [], isSelected: false },
          { value: 'philanthropists', url: '/social/friends/philanthropists', children: [], isSelected: false },
          { value: 'participate', url: '/social/participate', children: [], isSelected: false }
        ],
        valuesLeft: [
          { value: 'Corporate', url: '/corporate', isSelected: false },
          { value: 'Social', url: '/social', isSelected: true }
        ],
        bgColor: '#000000',
        color: '#ffffff',
        hoverColor: '#ffffff',
        isUpperCase: true
      },
      '/social/programs/skills-seminars': {
        name: 'Skills Seminars',
        valuesRight: [
          { value: 'programs', url: '/social/programs', children: [], isSelected: true },
          { value: 'supporters', url: '/social/friends/supporters', children: [], isSelected: false },
          { value: 'philanthropists', url: '/social/friends/philanthropists', children: [], isSelected: false },
          { value: 'participate', url: '/social/participate', children: [], isSelected: false }
        ],
        valuesLeft: [
          { value: 'Corporate', url: '/corporate', isSelected: false },
          { value: 'Social', url: '/social', isSelected: true }
        ],
        bgColor: '#000000',
        color: '#ffffff',
        hoverColor: '#ffffff',
        isUpperCase: true
      },
      '/testimonials': {
        name: 'Testimonials',
        valuesRight: [],
        valuesLeft: [
          { value: 'Corporate', url: '/corporate', isSelected: false },
          { value: 'Social', url: '/social', isSelected: false }
        ],
        bgColor: '#000000',
        color: '#ffffff',
        hoverColor: '#ffffff',
        isUpperCase: true
      },
      '/page-not-found': {
        name: 'Page not found',
        valuesRight: [],
        valuesLeft: [
          { value: 'Corporate', url: '/corporate', isSelected: false },
          { value: 'Social', url: '/social', isSelected: false }
        ],
        bgColor: '#000000',
        color: '#ffffff',
        hoverColor: '#ffffff',
        isUpperCase: true
      }
    };
  }

  private getItem(id) {
    let returnItem: any;
    const items = _.clone(this.menuItems());
    if (id.includes('/social/programs/pti')) {
      returnItem = items['/social/programs/pti'];
    } else if (items && !items[id]) {
      returnItem = items['/page-not-found'];
    } else {
      returnItem = items[id] || {};
    }

    return returnItem;
  }

  public retrieveMenu(id) {
    const item = this.getItem(id);
    return item;
  }

}
