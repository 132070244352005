import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { HeaderService } from 'src/app/services/header.service';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/services/language.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {




  public menu: any = {};
  public menuValuesLeftPart: any = [];
  public menuValuesRightPart: any = [];
  public isTest = false;
  public lang: { code: string, name: string };
  public languages = [];

  constructor(
    private route: Router,
    private headerService: HeaderService,
    private titleService: Title,
    private translate: TranslateService,
    private languageService: LanguageService
  ) {
    this.routeEvent(this.route);
  }  

  public setPreferredLanguage(item) {
    this.languageService.setLanguage(item);
    this.translate.use(item.code);
    const lang = this.languageService.getLanguage();
    this.lang = lang;
    
    window.location.reload();
  }

  public goHome() {
    this.route.navigateByUrl('/home');
  }

  routeEvent(router: Router) {
    router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        if((window as any).getFontSize) { (window as any).getFontSize() }
        if((window as any).checkCookie) {
          if((window as any).checkCookie('ccontrast')) {
            if((window as any).addConstrast) {(window as any).addConstrast();}
          } else {
            if((window as any).removeConstrast) {(window as any).removeConstrast();}
          }
        }

        // console.log(e);
        window.scrollTo(0, 0);
        const currentRoute = e.urlAfterRedirects;
        this.menu = this.headerService.retrieveMenu(currentRoute);

        if (this.menu.name === 'Test2' || this.menu.name === 'Test3') {
          this.isTest = true;
        } else {
          this.isTest = false;
        }

        this.setTitle('Knowl - ' + this.menu.name);
        this.menuValuesLeftPart = this.menu.valuesLeft;
        this.menuValuesRightPart = this.menu.valuesRight;
      }
    });
  }

  ngOnInit() {
    this.languages = this.languageService.loadLanguages();
    this.lang = this.languageService.getLanguage();
  }

  private setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }

}
