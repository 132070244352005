import { Component, OnInit } from '@angular/core';
import { LanguageService } from '../services/language.service';

@Component({
  selector: 'app-homellon',
  templateUrl: './homellon.component.html',
  styleUrls: ['./homellon.component.css']
})
export class HomellonComponent implements OnInit {

  public lang: { code: string, name: string };

  constructor(private languageService: LanguageService) { }

  ngOnInit() {
    this.lang = this.languageService.getLanguage();
  }

}
