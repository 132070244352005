import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from "@angular/core";
import { DalService } from "../services/dal.service";
import { HttpClient } from "@angular/common/http";
import { LanguageService } from "../services/language.service";

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: "app-pti",
  templateUrl: "./pti.component.html",
  styleUrls: ["./pti.component.css"],
})
export class PtiComponent implements OnInit {
  public pageData: any;
  public logos: any[] = [];
  public lang: { code: string, name: string };

  constructor(
    public dalService: DalService,
    private http: HttpClient,
    public cdr: ChangeDetectorRef,
    public languageService: LanguageService
  ) {}

  public ngOnInit() {
    this.lang = this.languageService.getLanguage();
    this.getData();
  }

  public async getData() {
    this.pageData = await this.get();
    this.logos = this.pageData.logos;

    this.cdr.detectChanges();
  }

  public get() {
    return new Promise((resolve, reject) => {
      return this.http
        .get<any>("https://adminpanel.knowl.gr/apionline/getpageptiHome?lang=" + this.lang.code)
        .subscribe(
          (res) => {
            resolve(res);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }
}
