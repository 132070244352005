import { Component, OnInit, HostListener, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-scroll-top',
  templateUrl: './scroll-top.component.html',
  styleUrls: ['./scroll-top.component.css']
})
export class ScrollTopComponent implements OnInit {

  public isShow: boolean;
  public topPosToStartShowing = 100;

  constructor(
    public cdr: ChangeDetectorRef
  ) { }

  public ngOnInit() {
    // do nothing
  }

  @HostListener('window:scroll') checkScroll() {

    // window scroll top
    // Both window.pageYOffset and document.documentElement.scrollTop returns the same result in all the cases.
    // window.pageYOffset is not supported below IE 9.

    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

    // console.log('[scroll]', scrollPosition);

    if (scrollPosition >= this.topPosToStartShowing) {
      this.isShow = true;
    } else {
      this.isShow = false;
    }

    // Apply changes
    this.cdr.detectChanges();
  }

  // TODO: Cross browsing
  public gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

}
