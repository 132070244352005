import {
  Component,
  OnInit,
  ChangeDetectorRef,
  HostListener,
  ViewChild,
  ElementRef,
  ViewRef,
  OnDestroy,
  ChangeDetectionStrategy,
} from "@angular/core";
import { DalService } from "../services/dal.service";
import { HttpClient } from "@angular/common/http";
import * as _ from "underscore";
import { ModalService } from "../services/modal.service";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { FormBuilder, FormArray, FormGroup } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { Meta, MetaDefinition, Title } from "@angular/platform-browser";
import { LanguageService } from "../services/language.service";

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: "app-pti-programs",
  templateUrl: "./pti-programs.component.html",
  styleUrls: ["./pti-programs.component.css"],
})
export class PtiProgramsComponent implements OnInit, OnDestroy {
  public lang: { code: string, name: string };
  public pageData: any;
  public urlParamId: any;
  public formProperties: any[] = [];
  public contactForm: any = {};
  public totalfiles: Array<File> = [];
  public totalFileName = [];
  public hasFileInput = false;
  public fileInputInfo: any = {};
  public fileToUpload: File = null;
  public counterFiles = 0;
  public isFormAvailable = false;
  public isFormSubmitted = false;
  @ViewChild("fileInputItem") fileInputItem: ElementRef;

  constructor(
    public dalService: DalService,
    private http: HttpClient,
    public cdr: ChangeDetectorRef,
    public modalService: ModalService,
    private route: ActivatedRoute,
    private router: Router,
    public toastr: ToastrService,
    public fb: FormBuilder,
    public translate: TranslateService,
    private titleService: Title,
    private metaService: Meta,
    private languageService: LanguageService
  ) {}

  public applicationForm = this.fb.group({
    formElements: this.fb.array([]),
    photoItems: this.fb.array([]),
  });

  public async ngOnInit() {
    this.lang = this.languageService.getLanguage();

    this.urlParamId = this.route.snapshot.paramMap.get("id");

    // Apply changes
    this.cdr.detectChanges();

    // Get data
    this.getData();
  }

  public async getData() {
    const retrievedData = await this.get();

    this.pageData = retrievedData;
    this.formProperties = this.pageData.formdata;
    if (
      this.formProperties &&
      _.isArray(this.formProperties) &&
      !!this.formProperties.length
    ) {
      this.isFormAvailable = true;
    } else {
      this.isFormAvailable = false;
    }

    // Loop data
    _.each(this.formProperties, (item) => {
      // Build form
      this.addFormElement(item);
    });

    _.each(
      (this.applicationForm.get("formElements") as FormArray).controls,
      (item, index) => {
        // Take actual values to fill formGroup[] into givenValue property with formArray type
        const values = this.formProperties[index].values;

        // Create formGroups into givenValue formArray property
        if (item.value.type === "checkbox-group") {
          _.each(values, (val) => {
            const givenValuesProperty = item.get("givenValues") as FormArray;
            givenValuesProperty.push(this.addFormElementOptions(val));
          });
        }

        if (item.value.type === "file") {
          this.fileInputInfo = item.value;
          this.hasFileInput = true;
        }
      }
    );

    // Add photo
    this.addPhotoItem();

    // Set meta data and open graph data
    this.setMeta();

    // Set title
    if (this.pageData.top_title) {
      this.titleService.setTitle("Knowl - " + this.pageData.top_title);
    }

    // Apply changes
    if (!(this.cdr as ViewRef).destroyed) {
      this.cdr.detectChanges();
    }
  }

  get formElements(): FormArray {
    return this.applicationForm.get("formElements") as FormArray;
  }

  public addFormElement(item) {
    this.formElements.push(this.createFormElement(item));
  }

  public createFormElement(item): FormGroup {
    const group = this.fb.group({
      label: item.label,
      required: item.required
        ? item.required === true || item.required === "true"
          ? true
          : false
        : false,
      type: item.type,
      name: item.name,
      givenValues:
        item.type === "checkbox-group" ? this.fb.array([]) : [item.values],
      value: "",
    });

    return group;
  }

  public addFormElementOptions(item): FormGroup {
    return this.fb.group({
      label: item.label,
      value: item.value,
      selected: item.selected
        ? item.selected === true || item.selected === "true"
          ? true
          : false
        : false,
    });
  }

  public createUploadDocuments(): FormGroup {
    return this.fb.group({
      documentFile: File,
    });
  }

  get photos(): FormArray {
    return this.applicationForm.get("photoItems") as FormArray;
  }

  public addPhotoItem(): void {
    this.photos.push(this.createUploadDocuments());
  }

  public fileSelectionEvent(fileInput: any, oldIndex) {
    if (fileInput.target.files && fileInput.target.files[0]) {
      const reader = new FileReader();
      reader.onload = () => {};
      this.totalfiles[oldIndex] = fileInput.target.files[0];
      this.totalFileName[oldIndex] = fileInput.target.files[0].name;

      reader.readAsDataURL(fileInput.target.files[0]);
    }
  }

  public handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
  }

  public checkFormValidation() {
    this.counterFiles =
      _.where(this.applicationForm.value.formElements, {
        type: "file",
        required: true,
      }).length || 0;

    let validationForm = false;
    if (this.applicationForm.valid) {
      if (this.counterFiles > 0) {
        if (this.totalfiles.length > 0) {
          validationForm = true;
        } else {
          validationForm = false;
        }
      } else {
        validationForm = true;
      }
    }

    const checkboxGroupValidation = this.validateCheckboxes();

    return validationForm && checkboxGroupValidation;
  }

  public validateCheckboxes() {
    const groups = _.where(this.applicationForm.value.formElements, {
      type: "checkbox-group",
      required: true,
    });
    const groupsLength = groups.length || 0;

    let groupCounter = 0;

    _.each(groups, (element: any) => {
      let group = _.findWhere(element.givenValues, { selected: true });
      if (!_.isEmpty(group)) {
        groupCounter++;
      }
    });
    return groupsLength > groupCounter ? false : true;
  }

  public async onSubmit() {
    // console.log(this.applicationForm);
    // console.log(this.applicationForm.value);

    if (!this.checkFormValidation()) {
      this.toastr.warning("Παρακαλώ συμπληρώστε όλα τα υποχρεωτικά πεδία");
      return;
    }

    let respPost: any;

    // Get photo
    const photoImage = this.totalfiles[0];
    if (photoImage && photoImage.name) {
      // Create new formData
      const photoFormData: FormData = new FormData();
      // Append photo to formData
      photoFormData.append("photo", this.totalfiles[0]);
      // Post photo and get an id
      respPost = await this.postPhoto(photoFormData);
    }

    // Structure the rest data to send them
    const obj: any = {
      id: respPost && respPost.replyid ? respPost.replyid : 0,
      form_id: this.pageData.form_id,
      form_submition_structure: JSON.stringify(this.applicationForm.value),
    };

    // Then post the rest form
    await this.postRestForm(JSON.stringify(obj));
  }

  public sendMessage() {
    // this.closeModal();

    // Clear selected file
    if (this.fileInputItem && this.fileInputItem.nativeElement) {
      this.fileInputItem.nativeElement.value = "";
      this.totalFileName = [];
      this.totalfiles = [];
    }

    // Clear former values
    this.clearFormArray(this.formElements);
    this.clearFormArray(this.photos);

    this.isFormSubmitted = true;

    // Apply changes
    if (!(this.cdr as ViewRef).destroyed) {
      this.cdr.detectChanges();
    }

    // Init again
    this.getData();

    this.gotoTop();
  }

  public get() {
    return new Promise((resolve, reject) => {
      return this.http
        .get<any>(
          "https://adminpanel.knowl.gr/apionline/getpageGetinto?pagename=" +
            this.urlParamId + "&lang=" + this.lang.code
        )
        .subscribe(
          (res) => {
            resolve(res);
          },
          (error) => {
            window.scrollTo(0, 0);
            this.router.navigate(["/page-not-found"]);
            reject(error);
          }
        );
    });
  }

  public async postPhoto(photoFormData) {
    return new Promise((resolve, reject) => {
      // const httpOptions = this.dalService.getHeaders();
      return this.http
        .post<any>(
          "https://adminpanel.knowl.gr/apionline/submitFormAttach",
          photoFormData
        )
        .subscribe(
          (res) => {
            resolve(res);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  public async postRestForm(data) {
    return new Promise((resolve, reject) => {
      return this.http
        .post<any>("https://adminpanel.knowl.gr/apionline/submitForm", data, {})
        .subscribe(
          (res) => {
            this.sendMessage();
            resolve(res);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  public ngOnDestroy() {
    this.metaService.removeTag("name='description'");
    this.metaService.removeTag("name='keywords'");
    this.metaService.removeTag("property='og:description'");
    this.metaService.removeTag("property='og:image'");
    this.metaService.removeTag("property='og:image:secure_url'");
    this.metaService.removeTag("property='og:title'");
    this.metaService.removeTag("property='og:url'");
  }

  // Clear formArray
  private clearFormArray = (formArray: FormArray) => {
    while (formArray.length !== 0) {
      formArray.removeAt(0);
    }
  };

  private setMeta() {
    this.metaService.addTag({
      name: "description",
      content: this.pageData.meta_description,
    });
    this.metaService.addTag({
      name: "keywords",
      content: this.pageData.meta_keywords,
    });
    this.metaService.addTag({
      property: "og:description",
      content: this.pageData.og_description,
    });
    this.metaService.addTag({
      property: "og:image",
      content:
        "https://adminpanel.knowl.gr/assets/uploads/getinto/" +
        this.pageData.og_image,
    });
    this.metaService.addTag({
      property: "og:image:secure_url",
      content:
        "https://adminpanel.knowl.gr/assets/uploads/getinto/" +
        this.pageData.og_image,
    });
    this.metaService.addTag({
      property: "og:title",
      content: this.pageData.og_title,
    });
    this.metaService.addTag({
      property: "og:url",
      content: window.location.href,
    });
  }

  private gotoTop() {
    const element = document.querySelector("#destination");
    if (element) element.scrollIntoView({ behavior: "smooth", block: "start" });
  }
}
