import { Component, OnInit } from '@angular/core';
import { LanguageService } from '../services/language.service';

@Component({
  selector: 'app-mellon-abilities',
  templateUrl: './mellon-abilities.component.html',
  styleUrls: ['./mellon-abilities.component.css']
})
export class MellonAbilitiesComponent implements OnInit {

  public lang: { code: string, name: string };
  
  constructor(private languageService: LanguageService) { }

  ngOnInit() {
     this.lang = this.languageService.getLanguage();
  }

}
