import { BrowserModule, Meta } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './common/header/header.component';
import { HomeComponent } from './home/home.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { FooterComponent } from './common/footer/footer.component';
import { MenuItemHighlightDirective } from './directives/menu-item-highlight.directive';
import { SocialComponent } from './social/social.component';
import { CorporateComponent } from './corporate/corporate.component';
import { ParticipateComponent } from './participate/participate.component';
import { ModalComponent } from './common/modal/modal.component';
import { ClientsComponent } from './clients/clients.component';
import { EuropeanComponent } from './european/european.component';
import { EuropeanBoxComponent } from './european/european-box/european-box.component';
import { ServicesComponent } from './services-screen/services.component';
import { PhilanthropistsComponent } from './philanthropists/philanthropists.component';
import { SupportersComponent } from './supporters/supporters.component';
import { SocialProgramsComponent } from './social-programs/social-programs.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ScrollTopComponent } from './scroll-top/scroll-top.component';
import { HomellonComponent } from './homellon/homellon.component';
import { TestimonialsComponent } from './testimonials/testimonials.component';
import { TestimonialsTypeOneComponent } from './testimonials/testimonials-type-one/testimonials-type-one.component';
import { TestimonialsTypeTwoComponent } from './testimonials/testimonials-type-two/testimonials-type-two.component';
import { PtiComponent } from './pti/pti.component';
import { PtiProgramsComponent } from './pti-programs/pti-programs.component';
import { MatchingFitsComponent } from './matching-fits/matching-fits.component';
import { SkillsSeminarsComponent } from './skills-seminars/skills-seminars.component';
import { MellonAbilitiesComponent } from './mellon-abilities/mellon-abilities.component';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HomeComponent,
    PageNotFoundComponent,
    FooterComponent,
    MenuItemHighlightDirective,
    SocialComponent,
    CorporateComponent,
    ParticipateComponent,
    ModalComponent,
    ClientsComponent,
    EuropeanComponent,
    EuropeanBoxComponent,
    ServicesComponent,
    PhilanthropistsComponent,
    SupportersComponent,
    SocialProgramsComponent,
    ScrollTopComponent,
    HomellonComponent,
    TestimonialsComponent,
    TestimonialsTypeOneComponent,
    TestimonialsTypeTwoComponent,
    PtiComponent,
    PtiProgramsComponent,
    MatchingFitsComponent,
    SkillsSeminarsComponent,
    MellonAbilitiesComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot({
      timeOut: 100000,
      positionClass: 'toast-top-center',
      preventDuplicates: true,
      closeButton: true,
      progressBar: false,
      extendedTimeOut: 100000
    }), // ToastrModule added
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }), TooltipModule.forRoot()
  ],
  providers: [
    Meta
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
