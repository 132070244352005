import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  HostListener,
  ViewChild,
  ElementRef,
  ViewRef,
} from "@angular/core";
import { TeamService } from "src/app/services/team.service";
import { ModalService } from "../services/modal.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { DalService } from "../services/dal.service";
import * as _ from "underscore";
import { Title } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";
import { LanguageService } from "../services/language.service";

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"],
})
export class HomeComponent implements OnInit {
  public teamMembers: any = [];
  public teamMembersRow1: any = [];
  public teamMembersRow2: any = [];
  public teamMembersRow3: any = [];
  public teamMemberDescription = "";
  public member: any;
  public homeData: any = {};
  public programsCounter = 0;
  httpOptions: { headers: any };
  storeService: any;
  @ViewChild("modalScroll") modalScroll: ElementRef;
  public lang: { code: string, name: string };

  constructor(
    public teamService: TeamService,
    public modalService: ModalService,
    public cdr: ChangeDetectorRef,
    public dalService: DalService,
    private http: HttpClient,
    private titleService: Title,
    public translate: TranslateService,
    public languageService: LanguageService
  ) {}

  public async ngOnInit() {
    this.lang = this.languageService.getLanguage();
    this.getData();
  }

  public async getData() {
    this.homeData = await this.get();
    this.teamMembers = this.homeData.team || [];

    this.fillTeamData();

    this.programsCount();

    // Apply changes
    if (!(this.cdr as ViewRef).destroyed) {
      this.cdr.detectChanges();
    }
  }

  public fillTeamData() {
    if (this.teamMembers && this.teamMembers.length > 0) {
      _.each(this.teamMembers, (member, index) => {
        if (index <= 3) {
          this.teamMembersRow1.push(member);
        }
      });
    }

    if (this.teamMembers && this.teamMembers.length > 3) {
      _.each(this.teamMembers, (member, index) => {
        if (index > 3 && index <= 7) {
          this.teamMembersRow2.push(member);
        }
      });
    }

    if (this.teamMembers && this.teamMembers.length > 7) {
      _.each(this.teamMembers, (member, index) => {
        if (index > 7) {
          this.teamMembersRow3.push(member);
        }
      });
    }
  }

  public get() {
    return new Promise((resolve, reject) => {
      return this.http
        .get<any>("https://adminpanel.knowl.gr/apionline/getpageHome?lang=" + this.lang.code)
        .subscribe(
          (res) => {
            resolve(res);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  public openRelateModal(member): void {
    // Clear member
    this.member = {};
    // Apply changes
    if (!(this.cdr as ViewRef).destroyed) {
      this.cdr.detectChanges();
    }
    this.member = member;
    // Trigger opening modal
    this.modalService.open("team-member-modal");
    // Scroll content to top
    this.scrollToTop();
    // Apply changes
    if (!(this.cdr as ViewRef).destroyed) {
      this.cdr.detectChanges();
    }
    // Set tab title
    this.setTitle("Knowl - " + member.firstname + " " + member.lastname);
  }

  public closeModal() {
    // Clear member
    this.member = {};
    this.modalService.close("team-member-modal");
    // Apply changes
    if (!(this.cdr as ViewRef).destroyed) {
      this.cdr.detectChanges();
    }
  }

  public programsCount() {
    this.programsCounter = 0;
    if (this.homeData.available_program_image1) {
      this.programsCounter++;
    }
    if (this.homeData.available_program_image2) {
      this.programsCounter++;
    }
    if (this.homeData.available_program_image3) {
      this.programsCounter++;
    }
    // Apply changes
    if (!(this.cdr as ViewRef).destroyed) {
      this.cdr.detectChanges();
    }
  }

  @HostListener("document:keydown", ["$event"]) public closeCreateModal(
    event?: KeyboardEvent
  ) {
    if (!event || event.keyCode === 27) {
      this.closeModal();
    }
  }

  private scrollToTop() {
    this.modalScroll.nativeElement.scrollTo(0, 0);
  }

  private setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }
}
