import { Component, OnInit, ChangeDetectorRef, HostListener, ViewChild, ElementRef } from '@angular/core';
import { ModalService } from 'src/app/services/modal.service';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { HeaderService } from 'src/app/services/header.service';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  public year: number;
  public isTest = false;
  @ViewChild('modalScroll') modalScroll: ElementRef;
  public lang: { code: string, name: string };

  constructor(
    private route: Router,
    private headerService: HeaderService,
    public cdr: ChangeDetectorRef,
    public modalService: ModalService,
    private languageService: LanguageService
  ) {    
    this.routeEvent(this.route);
  }

  routeEvent(router: Router) {
    router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        const currentRoute = e.urlAfterRedirects;
        const menu = this.headerService.retrieveMenu(currentRoute);

        if (menu.name === 'Test2' || menu.name === 'Test3') {
          this.isTest = true;
        } else {
          this.isTest = false;
        }
      }
    });
  }

  public ngOnInit() {
    this.year = (new Date()).getFullYear();
    this.lang = this.languageService.getLanguage();
  }


  public openDiclaimerModal(): void {
    // Trigger opening modal
    this.modalService.open('disclaimer');
    // Scroll content to top
    this.scrollToTop();
    // Apply changes
    this.cdr.detectChanges();
  }

  public closeModal() {
    this.modalService.close('disclaimer');
  }

  @HostListener('document:keydown', ['$event']) public closeCreateModal(event?: KeyboardEvent) {
    if (!event || event.keyCode === 27) {
      this.closeModal();
    }
  }

  private scrollToTop() {
    this.modalScroll.nativeElement.scrollTo(0, 0);
  }

}
