import { Component, OnInit, ChangeDetectorRef, ViewRef } from "@angular/core";
import { DalService } from "../services/dal.service";
import { HttpClient } from "@angular/common/http";
import { LanguageService } from "../services/language.service";

@Component({
  selector: "app-european",
  templateUrl: "./european.component.html",
  styleUrls: ["./european.component.css"],
})
export class EuropeanComponent implements OnInit {
  public pageData: any;
  public totalCount = 0;
  public pageCountRequest = 1;
  public lang: { code: string, name: string };

  constructor(
    public dalService: DalService,
    private http: HttpClient,
    public cdr: ChangeDetectorRef,
    public languageService: LanguageService
  ) {}

  public europeanPrograms: any[] = [];

  public async ngOnInit() {
    this.lang = this.languageService.getLanguage();
    // this.europeanPrograms = this.getPrograms();
    this.getData();
  }

  public async getData() {
    this.pageData = await this.get();
    this.europeanPrograms = this.pageData.programs;
    this.totalCount =
      this.pageData && this.pageData.totalcount ? this.pageData.totalcount : 0;
    // Apply changes
    if (!(this.cdr as ViewRef).destroyed) {
      this.cdr.detectChanges();
    }
  }

  public get() {
    return new Promise((resolve, reject) => {
      return this.http
        .get<any>("https://adminpanel.knowl.gr/apionline/getpageEuropean?lang=" + this.lang.code)
        .subscribe(
          (res) => {
            resolve(res);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  public loadMore() {
    if ((window as any).getFontSize) {
      (window as any).getFontSize();
    }
    return new Promise((resolve, reject) => {
      return this.http
        .get<any>(
          "https://adminpanel.knowl.gr/apionline/getpageEuropeanMore?p=" + this.pageCountRequest + "&lang=" + this.lang.code
        )
        .subscribe(
          (res: any) => {
            this.europeanPrograms =
              res.programs && res.programs.length
                ? this.europeanPrograms.concat(res.programs)
                : [];
            this.pageCountRequest++;
            // Apply changes
            if (!(this.cdr as ViewRef).destroyed) {
              this.cdr.detectChanges();
            }
            resolve(res);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }
}
