import { Injectable, Output, EventEmitter } from '@angular/core';
import * as _ from 'underscore';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DalService {
  public getHeaders() {
    return {
      headers: new HttpHeaders({
        // 'Access-Control-Allow-Origin': 'true',
        // 'Content-Type': 'application/json',
        // 'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
        // 'Pragma': 'no-cache',
        // 'Expires': '0'
      })
    };
  }

}
