import { Component, OnInit, OnDestroy, Input, ElementRef, ViewEncapsulation, ChangeDetectorRef, SimpleChanges } from '@angular/core';
import * as _ from 'underscore';
import { ModalService } from 'src/app/services/modal.service';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { HeaderService } from 'src/app/services/header.service';

@Component({
  selector: 'ngx-modal',
  styleUrls: ['modal.component.scss'],
  templateUrl: 'modal.component.html',
  encapsulation: ViewEncapsulation.None
})
export class ModalComponent implements OnInit, OnDestroy {
  @Input() public id: string;
  @Input() public closeModal = true;
  @Input() public width = '900px';
  @Input() public minheight?: string = 'auto';
  private element: any;

  constructor(
    public modalService: ModalService,
    public el: ElementRef,
    private titleService: Title,
    private headerService: HeaderService,
    private router: Router,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef
  ) {
    this.element = el.nativeElement;
  }

  public ngOnInit(): void {

    let modal = this;

    // ensure id attribute exists
    if (!this.id) {
      console.error('modal must have an id');
      return;
    }

    // move element to bottom of page (just before </body>) so it can be displayed above everything else
    document.body.appendChild(this.element);

    // add self (this modal instance) to the modal service so it's accessible from controllers
    this.modalService.add(this);
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.minheight && !changes.minheight.isFirstChange()) {
      this.minheight = changes.minheight.currentValue;

      // Apply changes
      this.cdr.detectChanges();
    }
  }

  // remove self from modal service when directive is destroyed
  public ngOnDestroy(): void {
    if (this.modalService && _.isFunction(this.modalService.remove)) this.modalService.remove(this.id);
    if (this.element && _.isFunction(this.element.remove)) this.element.remove();
  }

  // open modal
  public open(): void {
    this.element.style.display = 'block';
    document.body.classList.add('ngx-modal-open');
  }

  // close modal
  public close(): void {
    this.element.style.display = 'none';
    document.body.classList.remove('ngx-modal-open');
  }

  public xClose(): void {
    this.modalService.close(this.id);
    this.titleEvent();
  }

  private setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }

  private titleEvent() {
    this.route.url.subscribe((url) => {
      const menu = this.headerService.retrieveMenu('/' + url[0].path);
      this.setTitle('Knowl' + (menu.name ? (' - ' + menu.name) : ''));
      // Apply changes
      this.cdr.detectChanges();
    });
  }

}
