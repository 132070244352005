import {
  Component,
  OnInit,
  HostListener,
  ViewChild,
  ElementRef,
  ViewRef,
  ChangeDetectorRef,
} from "@angular/core";
import * as _ from "underscore";
import { DalService } from "../services/dal.service";
import { HttpClient } from "@angular/common/http";
import { Title } from "@angular/platform-browser";
import { ModalService } from "../services/modal.service";
import { LanguageService } from "../services/language.service";

@Component({
  selector: "app-skills-seminars",
  templateUrl: "./skills-seminars.component.html",
  styleUrls: ["./skills-seminars.component.css"],
})
export class SkillsSeminarsComponent implements OnInit {
  public words: any;
  public wordsSplitted: any = [];
  public activeFilterBox = 0;
  public seminars = [];

  public pageData: any;

  public totalCount = 0;
  public pageCountRequest = 0;
  public filterUsed = 0;

  public numArray1: any[] = [];
  public numArray2: any[] = [];
  public numArray3: any[] = [];
  public numArray4: any[] = [];
  public numArray5: any[] = [];

  public member: any = {};
  public lang: { code: string, name: string };

  @ViewChild("modalScroll") modalScroll: ElementRef;

  constructor(
    public dalService: DalService,
    private http: HttpClient,
    public cdr: ChangeDetectorRef,
    private titleService: Title,
    private modalService: ModalService,
    public languageService: LanguageService
  ) {}

  public async ngOnInit() {
    this.lang = this.languageService.getLanguage();
    this.words = ["ΓΝΩΣΗ", "ΓΙΑ", "ΟΛΟΥΣ"];
    this.splitLetters(this.words);

    // Get data
    await this.getData();
  }

  public setActiveFilter(value) {
    this.activeFilterBox = value;
    // Init again the page count
    this.pageCountRequest = 0;
    // Keep current filter
    this.filterUsed = value;
    // Call function
    this.getBoxes(value);
  }

  private scrollToTop() {
    this.modalScroll.nativeElement.scrollTo(0, 0);
  }

  private setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }

  public formatDate(date) {
    const d = new Date(date);
    const ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
    const mo = new Intl.DateTimeFormat("en", { month: "numeric" }).format(d);
    const da = new Intl.DateTimeFormat("en", { day: "numeric" }).format(d);

    return `${da}.${mo}.${ye}`;
  }

  public async getData() {
    this.pageData = await this.get();
    this.seminars = this.pageData.skillsseminars;
    this.totalCount =
      this.pageData && this.pageData.totalcount ? this.pageData.totalcount : 0;

    this.numArray1 = this.pageData.instructors.split("");
    this.numArray2 = this.pageData.participants.split("");
    this.numArray3 = this.pageData.seminars.split("");
    this.numArray4 = this.pageData.conferences.split("");
    this.numArray5 = this.pageData.workshops.split("");

    // Apply changes
    if (!(this.cdr as ViewRef).destroyed) {
      this.cdr.detectChanges();
    }
  }

  public get() {
    return new Promise((resolve, reject) => {
      return this.http
        .get<any>("https://adminpanel.knowl.gr/apionline/getpageSkillsseminars?lang=" + this.lang.code)
        .subscribe(
          (res) => {
            resolve(res);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  public getBoxes(param) {
    return new Promise((resolve, reject) => {
      // tslint:disable-next-line: max-line-length
      return this.http
        .get<any>(
          "https://adminpanel.knowl.gr/apionline/getpageSkillsseminars?f=" +
            param + "&lang=" + this.lang.code
        )
        .subscribe(
          (res: any) => {
            this.seminars =
              res && res.skillsseminars.length ? res.skillsseminars : [];
            this.totalCount =
              this.pageData && this.pageData.totalcount
                ? this.pageData.totalcount
                : 0;
            // Apply changes
            if (!(this.cdr as ViewRef).destroyed) {
              this.cdr.detectChanges();
            }
            resolve(res);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  public loadMore() {
    return new Promise((resolve, reject) => {
      // tslint:disable-next-line: max-line-length
      return this.http
        .get<any>(
          "https://adminpanel.knowl.gr/apionline/getpageSkillsseminarsMore?p=" +
            this.pageCountRequest +
            "&f=" +
            this.filterUsed + "&lang=" + this.lang.code
        )
        .subscribe(
          (res: any) => {
            // tslint:disable-next-line: max-line-length
            this.seminars =
              res.skillsseminars && res.skillsseminars.length
                ? this.seminars.concat(res.skillsseminars)
                : this.seminars;
            this.pageCountRequest++;
            this.cdr.detectChanges();
            resolve(res);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  public openRelateModal(member): void {
    // Clear member
    this.member = {};
    // Apply changes
    if (!(this.cdr as ViewRef).destroyed) {
      this.cdr.detectChanges();
    }
    this.member = member;
    // Trigger opening modal
    this.modalService.open("team-member-modal");
    // Scroll content to top
    this.scrollToTop();
    // Apply changes
    if (!(this.cdr as ViewRef).destroyed) {
      this.cdr.detectChanges();
    }
    // Set tab title
    this.setTitle("Knowl - " + member.sem_title);
  }

  public closeModal() {
    // Clear member
    this.member = {};
    this.modalService.close("team-member-modal");
    // Apply changes
    if (!(this.cdr as ViewRef).destroyed) {
      this.cdr.detectChanges();
    }
  }

  @HostListener("document:keydown", ["$event"]) public closePhilanthropistModal(
    event?: KeyboardEvent
  ) {
    if (!event || event.keyCode === 27) {
      this.closeModal();
    }
  }

  private splitLetters(words) {
    _.each(words, (word: any) => {
      // this.wordsSplitted.push(word.split('').reverse());
      this.wordsSplitted.push(word.split(""));
    });
  }
}
