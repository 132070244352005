import { Component, OnInit, ChangeDetectorRef, ViewRef } from "@angular/core";
import { DalService } from "../services/dal.service";
import { HttpClient } from "@angular/common/http";
import { LanguageService } from "../services/language.service";

@Component({
  selector: "app-social",
  templateUrl: "./social.component.html",
  styleUrls: ["./social.component.css"],
})
export class SocialComponent implements OnInit {
  public pageData: any;
  public programData: any;
  public programsCounter = 0;
  public lang: { code: string, name: string };

  constructor(
    public cdr: ChangeDetectorRef,
    public dalService: DalService,
    private http: HttpClient,
    public languageService: LanguageService
  ) {}

  ngOnInit() {
    this.lang = this.languageService.getLanguage();
    this.getData();
  }

  public async getData() {
    this.pageData = await this.get();
    this.programData = await this.getPrograms();
    this.programsCount();
    // Apply changes
    if (!(this.cdr as ViewRef).destroyed) {
      this.cdr.detectChanges();
    }
  }

  public getPrograms() {
    return new Promise((resolve, reject) => {
      return this.http
        .get<any>("https://adminpanel.knowl.gr/apionline/getpageHome?lang=" + this.lang.code)
        .subscribe(
          (res) => {
            resolve(res);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  public get() {
    return new Promise((resolve, reject) => {
      return this.http
        .get<any>("https://adminpanel.knowl.gr/apionline/getpageSocial?lang=" + this.lang.code)
        .subscribe(
          (res) => {
            resolve(res);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  public programsCount() {
    this.programsCounter = 0;
    if (this.programData.available_program_image1) {
      this.programsCounter++;
    }
    if (this.programData.available_program_image2) {
      this.programsCounter++;
    }
    if (this.programData.available_program_image3) {
      this.programsCounter++;
    }
    // Apply changes
    if (!(this.cdr as ViewRef).destroyed) {
      this.cdr.detectChanges();
    }
  }
}
