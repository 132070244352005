import { Component, OnInit, ChangeDetectorRef, ViewRef } from "@angular/core";
import { DalService } from "../services/dal.service";
import { HttpClient } from "@angular/common/http";
import * as _ from "underscore";
import { LanguageService } from "../services/language.service";

@Component({
  selector: "app-social-programs",
  templateUrl: "./social-programs.component.html",
  styleUrls: ["./social-programs.component.css"],
})
export class SocialProgramsComponent implements OnInit {
  public lang: { code: string, name: string };

  public pageData: any;
  public activeFilterBox = 0;

  public programs: any[] = [];
  public number1: any;
  public number2: any;
  public number3: any;
  public number4: any;
  public numberSplittedSection1: any = [];
  public numberSplittedSection2: any = [];
  public numberSplittedSection3: any = [];
  public numberSplittedSection4: any = [];
  public words: any;
  public wordsSplitted: any = [];

  constructor(
    public dalService: DalService,
    private http: HttpClient,
    public cdr: ChangeDetectorRef,
    private languageService: LanguageService
  ) {}

  public async ngOnInit() {
    this.lang = this.languageService.getLanguage();

    this.words = ["ΜΑΖΙ", "ΚΑΤΑΦΕΡΝΟΥΜΕ", "ΠΕΡΙΣΣΟΤΕΡΑ"];
    this.splitLetters(this.words);

    this.getData();
  }

  public async getData() {
    this.pageData = await this.get();
    this.number1 = this.pageData.education_hours;
    this.number2 = this.pageData.seminar_number;
    this.number3 = this.pageData.attendess_number;
    this.number4 = this.pageData.volunteers_number;
    this.numberSplittedSection1 = this.number1.split("");
    this.numberSplittedSection2 = this.number2.split("");
    this.numberSplittedSection3 = this.number3.split("");
    this.numberSplittedSection4 = this.number4.split("");
    this.programs = this.pageData.programs;
    // Apply changes
    if (!(this.cdr as ViewRef).destroyed) {
      this.cdr.detectChanges();
    }
  }

  public get() {
    return new Promise((resolve, reject) => {
      return this.http
        .get<any>("https://adminpanel.knowl.gr/apionline/getpagePrograms?lang=" + this.lang.code)
        .subscribe(
          (res) => {
            resolve(res);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  private splitLetters(words) {
    _.each(words, (word: any) => {
      this.wordsSplitted.push(word.split(""));
    });
  }

  public setActiveFilter(value) {
    this.activeFilterBox = value;
  }
}
