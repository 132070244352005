import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  constructor() { }

    private languages = [
      {code: 'el', name: 'Ελληνικά' },
      {code: 'en', name: 'English' }
    ];

  public setLanguage(item) {
    try {
    localStorage.setItem('preferredLang', JSON.stringify(item));
    } catch (error) { }
  }

  public getLanguage() {
    try {      
      const lang = JSON.parse(localStorage.getItem('preferredLang'));
      if(!lang) {this.setLanguage(this.languages[0])}
      return JSON.parse(localStorage.getItem('preferredLang'));
    } catch (error) {
      this.setLanguage(this.languages[0]);
      return this.languages[0];
    }    
  }

  loadLanguages() {
    return [...this.languages];
  }

}
